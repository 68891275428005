import * as Vue from "vue";
import { type RouteLocation, createRouter, createWebHistory } from "vue-router";
import { redirects } from "./redirects";
import authGuard from "@/router/guards/authGuard";

const routes = [
  ...redirects,
  // Self-parking sessions.
  {
    path: "/start",
    name: "start",
    component: () => import("@/views/LocationStartView.vue"),
    children: [
      {
        path: ":locationId",
        name: "startSelfParkingSession",
        component: () => import("@/views/Mobile/NewParkingSession.vue"),
      },
    ],
  },
  // Frictionless parking views.
  {
    path: "/f/:configId",
    name: "startFrictionlessParking",
    props: true,
    component: () => import("@/views/MobileV2View.vue"),
    children: [
      {
        // DeviceHub component fetches the device config and handles
        // routing to the appropriate frictionless parking flow.
        path: "",
        name: "deviceHub",
        meta: { scrollToTop: true },
        props: true,
        component: () => import("@/views/Frictionless/DeviceHub.vue"),
      },
      {
        path: "park",
        name: "cameraFlow",
        meta: { scrollToTop: true },
        props: true,
        component: () => import("@/views/Frictionless/CameraFlow.vue"),
      },
      {
        path: "gate",
        name: "gateKitFlow",
        meta: { scrollToTop: true },
        props: true,
        component: () => import("@/views/Frictionless/GateKitFlow.vue"),
      },
    ],
  },
  {
    path: "/f/session",
    name: "frictionlessParkingSession",
    component: () => import("@/views/MobileV2View.vue"),
    children: [
      {
        path: ":sessionId",
        name: "parkingSession",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Your Parking",
          scrollToTop: true,
        },
        props: true,
        component: () => import("@/views/Frictionless/ParkingSession.vue"),
      },
      {
        path: ":sessionId/setup",
        name: "parkingSetup",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Parking Setup",
          scrollToTop: true,
        },
        props: true,
        component: () => import("@/views/Frictionless/ParkingSetup.vue"),
      },
      {
        path: ":sessionId/receipt",
        name: "parkingReceipt",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Parking Receipt",
          scrollToTop: true,
        },
        props: true,
        component: () => import("@/views/Frictionless/ParkingReceipt.vue"),
      },
    ],
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/views/MobileV2View.vue"),
    children: [
      {
        path: "",
        name: "accountLanding",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Account",
          scrollToTop: true,
        },
        component: () => import("@/views/Frictionless/AccountLanding.vue"),
      },
      {
        path: "auth",
        name: "accountAuth",
        meta: {
          title: "PMC - Login",
          scrollToTop: true,
        },
        component: () =>
          import("@/views/Frictionless/AccountAuthentication.vue"),
      },
      {
        path: "vehicles",
        name: "accountVehicles",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Vehicles",
          scrollToTop: true,
        },
        component: () => import("@/views/Frictionless/AccountVehicles.vue"),
      },
      {
        path: "vehicles/add",
        name: "addVehicle",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Add Vehicle",
          scrollToTop: true,
        },
        component: () => import("@/views/Frictionless/AddVehicle.vue"),
      },
      {
        path: "vehicles/:vehicleTag",
        name: "accountVehicle",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Vehicle",
          scrollToTop: true,
        },
        props: true,
        component: () => import("@/views/Frictionless/AccountVehicle.vue"),
      },
      {
        path: "vehicles/:vehicleTag/edit",
        name: "editVehicle",
        meta: {
          title: "PMC - Edit Vehicle",
          scrollToTop: true,
        },
        props: true,
        component: () => import("@/views/Frictionless/EditVehicle.vue"),
      },
      {
        path: "payments",
        name: "accountPayments",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Payment Methods",
          scrollToTop: true,
        },
        component: () =>
          import("@/views/Frictionless/AccountPaymentMethods.vue"),
      },
      {
        path: "payments/:paymentMethodId",
        name: "viewPaymentMethod",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - View Payment Method",
          scrollToTop: true,
        },
        props: true,
        component: () =>
          import("@/views/Frictionless/AccountPaymentMethod.vue"),
      },
      {
        path: "history",
        name: "parkingHistory",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Parking History",
          scrollToTop: true,
        },
        component: () =>
          import("@/views/Frictionless/AccountParkingHistory.vue"),
      },
      {
        path: "history/session/:sessionId",
        name: "parkingHistorySessionDetails",
        beforeEnter: authGuard,
        props: true,
        meta: {
          title: "PMC - Parking Session Details",
          scrollToTop: true,
        },
        component: () =>
          import("@/views/Frictionless/ParkingHistorySessionDetails.vue"),
      },
      {
        path: "profile",
        name: "accountProfile",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Profile",
          scrollToTop: true,
        },
        component: () => import("@/views/Frictionless/AccountProfile.vue"),
      },
      {
        path: "preferences",
        name: "accountPreferences",
        beforeEnter: authGuard,
        meta: {
          title: "PMC - Preferences",
          scrollToTop: true,
        },
        component: () => import("@/views/Frictionless/AccountPreferences.vue"),
      },
    ],
  },
  {
    path: "/v",
    name: "validation",
    component: () => import("@/views/MobileV1View.vue"),
    children: [
      // Validation view (expecting a validation code).
      {
        path: ":locationId/:validationCode",
        name: "ValidationPage",
        component: () => import("@/views/Mobile/ApplyValidation.vue"),
      },
    ],
  },
  {
    path: "/session",
    name: "session",
    component: () => import("@/views/MobileV1View.vue"),
    children: [
      // Manage an existing parking session (expecting a valid sessionId).
      {
        path: ":sessionId",
        name: "manageParkingSession",
        component: () => import("@/views/Mobile/ManageParkingSession.vue"),
      },
      {
        path: ":sessionId/pay",
        name: "Pay Parking Session",
        component: () => import("@/views/Mobile/PayParkingSession.vue"),
      },
      {
        path: ":sessionId/request",
        name: "Request Vehicle",
        component: () =>
          import("@/views/Mobile/RequestVehicle/RequestVehicle.vue"),
      },
      // Used by customers that are sent a link to update guest room reservations.
      {
        path: ":sessionId/billToRoom",
        name: "Bill to Room",
        component: () => import("@/views/Mobile/BillToRoom.vue"),
      },
    ],
  },
  {
    path: "/c",
    name: "conversion",
    component: () => import("@/views/MobileV1View.vue"),
    children: [
      {
        path: "pay",
        name: "Pay Notice",
        component: () => import("@/views/Mobile/PayParkingNotice.vue"),
      },
    ],
  },
  {
    path: "/permits",
    name: "permits",
    component: () => import("@/views/MobileV1View.vue"),
    children: [
      {
        path: "pay/:permitId/:invoiceNumber",
        name: "Pay Permit Invoice",
        component: () => import("@/views/Mobile/PayParkingInvoice.vue"),
      },
    ],
  },
  {
    path: "/search/venues/nissan-stadium-nashville-tn",
    redirect: "/venues/nissan-stadium-nashville-tn",
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/SearchView.vue"),
  },
  {
    path: "/venues",
    name: "venues",
    component: () => import("@/views/EventsView.vue"),
    children: [
      {
        path: ":slug",
        name: "venue-by-slug",
        props: true,
        component: () => import("@/views/Events/VenueView.vue"),
      },
      {
        path: ":slug/event/:eventSlug",
        name: "venue-events",
        props: true,
        component: () => import("@/views/Events/ReserveParkingView.vue"),
      },
      {
        path: ":slug/pass/:passId",
        name: "venue-passes",
        props: true,
        component: () => import("@/views/Events/ReservePassParking.vue"),
        // TODO: Bring this back once we sell out 2025 season passes.
        // redirect: (to: MatcherLocation) => ({
        //   path: `/venues/${to.params.slug}`,
        // }),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
  scrollBehavior(to, _, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.meta?.scrollToTop) {
      return { top: 0 };
    }

    return;
  },
});

const DEFAULT_HTML_TITLE = "Pay for Parking";

router.afterEach((to: RouteLocation) => {
  Vue.nextTick(() => {
    document.title = (to?.meta?.title as string) || DEFAULT_HTML_TITLE;
  });
});

export default router;
