import { defineStore } from "pinia";
import moment from "moment";
import { jwtDecode, type JwtPayload } from "jwt-decode";
import type { GarageVehicle, ParkingHistorySession } from "@/types";
import { getParkingLocation } from "@/src/protectedApi";

export interface AppUserStoreState {
  user: IAMServiceUser | null;
  authToken: string | null;
  vehicles: GarageVehicle[];
  paymentMethods: any[];
  parkingHistorySessions: ParkingHistorySession[];
  locations: Map<string, PMC.Parking.Location>;
}

export const useAppUserStore = defineStore({
  id: "appUserStore",
  state: (): AppUserStoreState => ({
    user: null,
    authToken: null,
    vehicles: [],
    paymentMethods: [],
    parkingHistorySessions: [],
    locations: new Map(),
  }),
  getters: {
    isAuthenticated(): boolean {
      if (!this.authToken || !this.user) {
        return false;
      }

      // Check to ensure the token is not expired.
      const decoded = jwtDecode<JwtPayload>(this.authToken);
      const expirationMoment = moment.unix(decoded.exp as number);

      return expirationMoment.isAfter(moment());
    },
  },
  actions: {
    setUserData(user: IAMServiceUser, authToken: string) {
      this.user = user;
      this.authToken = authToken;
    },
    getUserData() {
      return { user: this.user, authToken: this.authToken };
    },
    resetUserData() {
      this.user = null;
      this.authToken = null;
    },
    signOut() {
      this.resetUserData();
    },
    async getLocation(locationId: string) {
      let location: PMC.Parking.Location | undefined =
        this.locations.get(locationId);

      if (!location) {
        location = await getParkingLocation(locationId).catch(() => undefined);
      }

      if (!location) {
        return null;
      }

      this.locations.set(locationId, location);

      return location;
    },
  },
});
