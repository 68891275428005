import type { PiniaPluginContext } from "pinia";
import type { AppUserStoreState } from "@/stores/app-user";

const LS_APP_USER_STORE_KEY = "PMCWebParking:appUserStore";

export default function PersistAppUserStorePlugin(context: PiniaPluginContext) {
  if (context.store.$id !== "appUserStore") {
    return {};
  }

  context.store.$onAction((event) => {
    if (event.name === "resetUserData") {
      localStorage.removeItem(LS_APP_USER_STORE_KEY);
    }

    if (event.name === "setUserData") {
      const [user, authToken] = event.args;

      localStorage.setItem(
        LS_APP_USER_STORE_KEY,
        JSON.stringify({ user, authToken }),
      );
    }
  });

  const appData = JSON.parse(
    localStorage.getItem(LS_APP_USER_STORE_KEY) || "{}",
  ) as AppUserStoreState;

  if (appData?.user && appData?.authToken) {
    context.store.setUserData(appData.user, appData.authToken);
  }

  return {};
}
