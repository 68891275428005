import type { RouteLocationNormalized } from "vue-router";
import { useAppUserStore } from "@/stores/app-user";

export default function authGuard(to: RouteLocationNormalized) {
  const appUserStore = useAppUserStore();

  if (to.name === "accountAuth") {
    appUserStore.signOut();

    return;
  }

  if (!appUserStore.isAuthenticated && to.name !== "accountAuth") {
    return { name: "accountAuth", query: { redirect: `${to.path}` } };
  }
}
